// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__HPG19{
 width: 1200px;
 margin: 0 auto;
}

.style_cards__ZVs7f{
    margin: 30px 0;
    display: flex;
    gap: 70px;
    flex-wrap: wrap;
}
.style_text__t1ScG{
    font-size: 34px;
    font-weight: 600;
    letter-spacing: 2px;
    text-align: center;
    margin-bottom: 50px;

   }

@media (max-width: 768px) {
    .style_container__HPG19{
        width: 100%;
        margin: 0 auto;
       }
       
       .style_cards__ZVs7f{
           margin: 30px 0;
           display: flex;
           gap: 20px;
           flex-wrap: wrap;
       }
       .style_mobile__RM0lk{
        margin: 0 auto;
       }
       .style_text__t1ScG{
        font-size: 28px;
        font-weight: 600;
        letter-spacing: 2px;
        text-align: center;
       }
}`, "",{"version":3,"sources":["webpack://./src/components/pages/service/style.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,cAAc;AACf;;AAEA;IACI,cAAc;IACd,aAAa;IACb,SAAS;IACT,eAAe;AACnB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;;GAEpB;;AAEH;IACI;QACI,WAAW;QACX,cAAc;OACf;;OAEA;WACI,cAAc;WACd,aAAa;WACb,SAAS;WACT,eAAe;OACnB;OACA;QACC,cAAc;OACf;OACA;QACC,eAAe;QACf,gBAAgB;QAChB,mBAAmB;QACnB,kBAAkB;OACnB;AACP","sourcesContent":[".container{\r\n width: 1200px;\r\n margin: 0 auto;\r\n}\r\n\r\n.cards{\r\n    margin: 30px 0;\r\n    display: flex;\r\n    gap: 70px;\r\n    flex-wrap: wrap;\r\n}\r\n.text{\r\n    font-size: 34px;\r\n    font-weight: 600;\r\n    letter-spacing: 2px;\r\n    text-align: center;\r\n    margin-bottom: 50px;\r\n\r\n   }\r\n\r\n@media (max-width: 768px) {\r\n    .container{\r\n        width: 100%;\r\n        margin: 0 auto;\r\n       }\r\n       \r\n       .cards{\r\n           margin: 30px 0;\r\n           display: flex;\r\n           gap: 20px;\r\n           flex-wrap: wrap;\r\n       }\r\n       .mobile{\r\n        margin: 0 auto;\r\n       }\r\n       .text{\r\n        font-size: 28px;\r\n        font-weight: 600;\r\n        letter-spacing: 2px;\r\n        text-align: center;\r\n       }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__HPG19`,
	"cards": `style_cards__ZVs7f`,
	"text": `style_text__t1ScG`,
	"mobile": `style_mobile__RM0lk`
};
export default ___CSS_LOADER_EXPORT___;
