// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_postContainier__hS35J{
    width: 1200px;
    margin: 0 auto;
    min-height: 600px;
    /* max-height: fit-content; */
}

.style_title__ek3\\+I{
    font-size: 32px;
    letter-spacing: 5px;
}

.style_flot__lWvfa{
    float: left;
    margin-right: 150px;
    margin-bottom: 150px;
}

.style_text__T2oO2{
    margin: 0px;
  color: rgb(63, 62, 62);
  /* max-width: 500px; */
  letter-spacing: 0.2rem;
  font-size: 17px;
}

@media (max-width: 768px) {
    .style_postContainier__hS35J{
        width: 100%;
        margin: 0 auto;
        min-height: 600px;
        /* max-height: fit-content; */
    }
    
    .style_title__ek3\\+I{
        font-size: 25px;
        letter-spacing: 5px;
        padding: 0 15px;
    }
    
    .style_flot__lWvfa{
        float:none;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        /* width: 100px; */
    }
    
    .style_text__T2oO2{
        margin: 0px;
      color: rgb(63, 62, 62);
      /* max-width: 500px; */
      letter-spacing: 0.2rem;
      font-size: 17px;
      padding: 0 20px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/pages/post/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;IACd,iBAAiB;IACjB,6BAA6B;AACjC;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,WAAW;EACb,sBAAsB;EACtB,sBAAsB;EACtB,sBAAsB;EACtB,eAAe;AACjB;;AAEA;IACI;QACI,WAAW;QACX,cAAc;QACd,iBAAiB;QACjB,6BAA6B;IACjC;;IAEA;QACI,eAAe;QACf,mBAAmB;QACnB,eAAe;IACnB;;IAEA;QACI,UAAU;QACV,aAAa;QACb,uBAAuB;QACvB,cAAc;QACd,kBAAkB;IACtB;;IAEA;QACI,WAAW;MACb,sBAAsB;MACtB,sBAAsB;MACtB,sBAAsB;MACtB,eAAe;MACf,eAAe;IACjB;AACJ","sourcesContent":[".postContainier{\r\n    width: 1200px;\r\n    margin: 0 auto;\r\n    min-height: 600px;\r\n    /* max-height: fit-content; */\r\n}\r\n\r\n.title{\r\n    font-size: 32px;\r\n    letter-spacing: 5px;\r\n}\r\n\r\n.flot{\r\n    float: left;\r\n    margin-right: 150px;\r\n    margin-bottom: 150px;\r\n}\r\n\r\n.text{\r\n    margin: 0px;\r\n  color: rgb(63, 62, 62);\r\n  /* max-width: 500px; */\r\n  letter-spacing: 0.2rem;\r\n  font-size: 17px;\r\n}\r\n\r\n@media (max-width: 768px) {\r\n    .postContainier{\r\n        width: 100%;\r\n        margin: 0 auto;\r\n        min-height: 600px;\r\n        /* max-height: fit-content; */\r\n    }\r\n    \r\n    .title{\r\n        font-size: 25px;\r\n        letter-spacing: 5px;\r\n        padding: 0 15px;\r\n    }\r\n    \r\n    .flot{\r\n        float:none;\r\n        display: flex;\r\n        justify-content: center;\r\n        margin: 0 auto;\r\n        /* width: 100px; */\r\n    }\r\n    \r\n    .text{\r\n        margin: 0px;\r\n      color: rgb(63, 62, 62);\r\n      /* max-width: 500px; */\r\n      letter-spacing: 0.2rem;\r\n      font-size: 17px;\r\n      padding: 0 20px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"postContainier": `style_postContainier__hS35J`,
	"title": `style_title__ek3+I`,
	"flot": `style_flot__lWvfa`,
	"text": `style_text__T2oO2`
};
export default ___CSS_LOADER_EXPORT___;
