// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_box__RB3-Q{
    /* Button */
position: relative;
width: 279px;
height: -moz-fit-content;
height: fit-content;
left: 0px;
top: 80px;
box-sizing: border-box;
border: 2px solid rgb(0, 0, 0);
box-shadow: -4px 4px 0px 0px rgb(0, 0, 0);
background: rgb(255, 255, 255);
}
.style_titleBlock__BbZ65{
    margin: 0 auto;
    width: 236px;
}
.style_menuPart__iay4w{
    margin: 0 auto;
    width: 249px;
}`, "",{"version":3,"sources":["webpack://./src/components/adminPannel/menu/style.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf,kBAAkB;AAClB,YAAY;AACZ,wBAAmB;AAAnB,mBAAmB;AACnB,SAAS;AACT,SAAS;AACT,sBAAsB;AACtB,8BAA8B;AAC9B,yCAAyC;AACzC,8BAA8B;AAC9B;AACA;IACI,cAAc;IACd,YAAY;AAChB;AACA;IACI,cAAc;IACd,YAAY;AAChB","sourcesContent":[".box{\n    /* Button */\nposition: relative;\nwidth: 279px;\nheight: fit-content;\nleft: 0px;\ntop: 80px;\nbox-sizing: border-box;\nborder: 2px solid rgb(0, 0, 0);\nbox-shadow: -4px 4px 0px 0px rgb(0, 0, 0);\nbackground: rgb(255, 255, 255);\n}\n.titleBlock{\n    margin: 0 auto;\n    width: 236px;\n}\n.menuPart{\n    margin: 0 auto;\n    width: 249px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `style_box__RB3-Q`,
	"titleBlock": `style_titleBlock__BbZ65`,
	"menuPart": `style_menuPart__iay4w`
};
export default ___CSS_LOADER_EXPORT___;
