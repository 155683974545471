// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs_slideContent__-XzhA{
    display: flex;
    flex-direction: column;
    width: 506px;
    align-items: end;
    gap: 42px;
    text-align: left;
    height: 500px;
    margin-top: 50px;
}
.tabs_sliderContent__48CB-{
    display: flex;
    gap: 40px;
    justify-content: space-between;
    align-items: center;
}
.tabs_tabImage__OyspY{
    width : 550px;
    height: 340px;
    pointer-events: "none"
}

@media (max-width: 500px) {
    .tabs_tabImage__OyspY{
        width : 250px;
        height: 150px;
    }
    .tabs_sliderContent__48CB-{
        display: flex;
        gap: 20px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .tabs_slideContent__-XzhA{
        display: flex;
        flex-direction: column;
        width: auto;
        align-items: center;
        gap: 42px;
        text-align: left;
        height: 500px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/tabs/tabs.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;IAChB,SAAS;IACT,gBAAgB;IAChB,aAAa;IACb,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,SAAS;IACT,8BAA8B;IAC9B,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,aAAa;IACb;AACJ;;AAEA;IACI;QACI,aAAa;QACb,aAAa;IACjB;IACA;QACI,aAAa;QACb,SAAS;QACT,sBAAsB;QACtB,8BAA8B;QAC9B,mBAAmB;IACvB;IACA;QACI,aAAa;QACb,sBAAsB;QACtB,WAAW;QACX,mBAAmB;QACnB,SAAS;QACT,gBAAgB;QAChB,aAAa;IACjB;AACJ","sourcesContent":[".slideContent{\n    display: flex;\n    flex-direction: column;\n    width: 506px;\n    align-items: end;\n    gap: 42px;\n    text-align: left;\n    height: 500px;\n    margin-top: 50px;\n}\n.sliderContent{\n    display: flex;\n    gap: 40px;\n    justify-content: space-between;\n    align-items: center;\n}\n.tabImage{\n    width : 550px;\n    height: 340px;\n    pointer-events: \"none\"\n}\n\n@media (max-width: 500px) {\n    .tabImage{\n        width : 250px;\n        height: 150px;\n    }\n    .sliderContent{\n        display: flex;\n        gap: 20px;\n        flex-direction: column;\n        justify-content: space-between;\n        align-items: center;\n    }\n    .slideContent{\n        display: flex;\n        flex-direction: column;\n        width: auto;\n        align-items: center;\n        gap: 42px;\n        text-align: left;\n        height: 500px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slideContent": `tabs_slideContent__-XzhA`,
	"sliderContent": `tabs_sliderContent__48CB-`,
	"tabImage": `tabs_tabImage__OyspY`
};
export default ___CSS_LOADER_EXPORT___;
