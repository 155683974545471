import { useParams, useLocation } from "react-router-dom"
import { useSelector } from "react-redux"
import { useEffect, useState } from "react";
import PhotoList from '../../PhotoList/index'
import style from './style.module.css'
import ReactMarkdown from "react-markdown";

function Post({ title, text, img }) {
    const params = useParams()
    const { examples } = useSelector((state) => state.examples);
    const { posts } = useSelector((state) => state.posts)
    const isLoading = examples.status == 'loaded'
    const [item, setItem] = useState(null)
    // console.log(params.id);
    const location = useLocation()


    useEffect(() => {
        if (isLoading) {
            if (examples.items.find(obj => obj._id == params.id) == undefined) {
                setItem(posts.items.find(obj => obj._id == params.id))
            } else {
                setItem(examples.items.find(obj => obj._id == params.id))
            }



        }

    }, [isLoading])


    return (
        <>
            {item != null ? <div>
                {
                    [item].map(e =>
                        <div className={style.postContainier}>
                            <p className={style.title}>{e.title}</p>
                            <div className={style.flot}>
                                {location.pathname.indexOf('service') != -1 ? <img width={350} src={e.imageUrl} /> : <PhotoList list={e.gallaryUrl} />}
                            </div>
                            <p className={style.text}>
                                <ReactMarkdown>
                                    {e.text}
                                </ReactMarkdown>
                            </p>
                            {/* {e.gallaryUrl.map(e => <p>{e}</p>)} */}
                        </div>
                    )
                }
            </div> : <p></p>}
        </>
    )
}

export default Post