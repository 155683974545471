// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_muneBtn__e1e3n{
display: flex;
flex-direction: row;
text-decoration: none;
gap: 15px;
padding: 10px 10px;
align-items: center;
color: black;
border: 2px solid rgb(0, 0, 0);
box-shadow: -4px 4px 0px 0px rgb(0, 0, 0);
background: rgb(255, 255, 255);
width: 230px;
margin-bottom: 15px;
}
.style_text__RFbC2{
    color: rgb(0, 0, 0);
line-height: 100%;
letter-spacing: 0%;
text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/components/adminPannel/button/style.module.css"],"names":[],"mappings":"AAAA;AACA,aAAa;AACb,mBAAmB;AACnB,qBAAqB;AACrB,SAAS;AACT,kBAAkB;AAClB,mBAAmB;AACnB,YAAY;AACZ,8BAA8B;AAC9B,yCAAyC;AACzC,8BAA8B;AAC9B,YAAY;AACZ,mBAAmB;AACnB;AACA;IACI,mBAAmB;AACvB,iBAAiB;AACjB,kBAAkB;AAClB,gBAAgB;AAChB","sourcesContent":[".muneBtn{\ndisplay: flex;\nflex-direction: row;\ntext-decoration: none;\ngap: 15px;\npadding: 10px 10px;\nalign-items: center;\ncolor: black;\nborder: 2px solid rgb(0, 0, 0);\nbox-shadow: -4px 4px 0px 0px rgb(0, 0, 0);\nbackground: rgb(255, 255, 255);\nwidth: 230px;\nmargin-bottom: 15px;\n}\n.text{\n    color: rgb(0, 0, 0);\nline-height: 100%;\nletter-spacing: 0%;\ntext-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"muneBtn": `style_muneBtn__e1e3n`,
	"text": `style_text__RFbC2`
};
export default ___CSS_LOADER_EXPORT___;
