// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer_footerContent__duq4o{
    width: 1200px;
    display: flex;
    margin: auto;
    justify-content: space-between;
    align-items: center;
}
.footer_social__eGScn{
    display: flex;
    gap: 10px;
}
.footer_social__eGScn a {
    border-radius: 30px;
    text-align: center;
    height: -moz-fit-content;
    height: fit-content;
}

@media (max-width: 500px){
    .footer_footerContent__duq4o{
        width: auto;
        display: flex;
        margin: auto;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column-reverse;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/footer/footer.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,YAAY;IACZ,8BAA8B;IAC9B,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,SAAS;AACb;AACA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,wBAAmB;IAAnB,mBAAmB;AACvB;;AAEA;IACI;QACI,WAAW;QACX,aAAa;QACb,YAAY;QACZ,6BAA6B;QAC7B,mBAAmB;QACnB,8BAA8B;IAClC;AACJ","sourcesContent":[".footerContent{\n    width: 1200px;\n    display: flex;\n    margin: auto;\n    justify-content: space-between;\n    align-items: center;\n}\n.social{\n    display: flex;\n    gap: 10px;\n}\n.social a {\n    border-radius: 30px;\n    text-align: center;\n    height: fit-content;\n}\n\n@media (max-width: 500px){\n    .footerContent{\n        width: auto;\n        display: flex;\n        margin: auto;\n        justify-content: space-evenly;\n        align-items: center;\n        flex-direction: column-reverse;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerContent": `footer_footerContent__duq4o`,
	"social": `footer_social__eGScn`
};
export default ___CSS_LOADER_EXPORT___;
