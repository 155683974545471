import Services from "../main/services"
import Works from "../main/works"
import About from "../main/about"

function HomePage(){
    return (
    <>
    <Services/>
    <Works/>
    <About/>
    </>
    )
}

export default HomePage;