// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.services_serviceContent__4aRZj{
    max-width: 1200px;
    margin: auto;
}
.services_serviceCard__w1quZ{
    display: grid;
    grid-template-columns: .2fr .2fr .2fr;
    justify-content: space-between;
    grid-template-rows: auto;
    margin-top: 60px;
}
@media (max-width: 1198px){
    .services_serviceCard__w1quZ{
        display: grid;
        grid-template-columns: .2fr .2fr;
        justify-content: space-around;
        margin-top: 60px;
    }
    .services_serviceContent__4aRZj{
        width: 1200px;
        margin: auto;
    }
}

@media (max-width: 500px){
    .services_serviceCard__w1quZ{
        display: grid;
        grid-template-columns: .2fr ;
        margin-top: 60px;
        gap: 40px;
    }
    .services_serviceContent__4aRZj{
        width: auto;
        margin: 130px auto;
        padding: 25px ;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/main/services.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,YAAY;AAChB;AACA;IACI,aAAa;IACb,qCAAqC;IACrC,8BAA8B;IAC9B,wBAAwB;IACxB,gBAAgB;AACpB;AACA;IACI;QACI,aAAa;QACb,gCAAgC;QAChC,6BAA6B;QAC7B,gBAAgB;IACpB;IACA;QACI,aAAa;QACb,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,aAAa;QACb,4BAA4B;QAC5B,gBAAgB;QAChB,SAAS;IACb;IACA;QACI,WAAW;QACX,kBAAkB;QAClB,cAAc;IAClB;AACJ","sourcesContent":[".serviceContent{\n    max-width: 1200px;\n    margin: auto;\n}\n.serviceCard{\n    display: grid;\n    grid-template-columns: .2fr .2fr .2fr;\n    justify-content: space-between;\n    grid-template-rows: auto;\n    margin-top: 60px;\n}\n@media (max-width: 1198px){\n    .serviceCard{\n        display: grid;\n        grid-template-columns: .2fr .2fr;\n        justify-content: space-around;\n        margin-top: 60px;\n    }\n    .serviceContent{\n        width: 1200px;\n        margin: auto;\n    }\n}\n\n@media (max-width: 500px){\n    .serviceCard{\n        display: grid;\n        grid-template-columns: .2fr ;\n        margin-top: 60px;\n        gap: 40px;\n    }\n    .serviceContent{\n        width: auto;\n        margin: 130px auto;\n        padding: 25px ;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"serviceContent": `services_serviceContent__4aRZj`,
	"serviceCard": `services_serviceCard__w1quZ`
};
export default ___CSS_LOADER_EXPORT___;
