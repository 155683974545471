// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_backgound__2souk {
    min-height: 1200px;
    max-height: -moz-fit-content;
    max-height: fit-content;
    background-color: #f4f6eb;
    padding: 0 0 70px 0;
    /* opacity: 0.6; */
    background: linear-gradient(135deg, #f1ece455 20%, transparent 20%) -37px 0/ 74px 74px, linear-gradient(225deg, #f1ece4 25%, transparent 25%) -37px 0/ 74px 74px, linear-gradient(315deg, #f1ece455 25%, transparent 25%) 0px 0/ 74px 74px, linear-gradient(45deg, #f1ece4 25%, #f4f6eb 25%) 0px 0/ 74px 74px;
}
.style_layout__4R91e{
    max-width: 1200px;
    margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/admin/style.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,4BAAuB;IAAvB,uBAAuB;IACvB,yBAAyB;IACzB,mBAAmB;IACnB,kBAAkB;IAClB,6SAA6S;AACjT;AACA;IACI,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":[".backgound {\n    min-height: 1200px;\n    max-height: fit-content;\n    background-color: #f4f6eb;\n    padding: 0 0 70px 0;\n    /* opacity: 0.6; */\n    background: linear-gradient(135deg, #f1ece455 20%, transparent 20%) -37px 0/ 74px 74px, linear-gradient(225deg, #f1ece4 25%, transparent 25%) -37px 0/ 74px 74px, linear-gradient(315deg, #f1ece455 25%, transparent 25%) 0px 0/ 74px 74px, linear-gradient(45deg, #f1ece4 25%, #f4f6eb 25%) 0px 0/ 74px 74px;\n}\n.layout{\n    max-width: 1200px;\n    margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backgound": `style_backgound__2souk`,
	"layout": `style_layout__4R91e`
};
export default ___CSS_LOADER_EXPORT___;
