// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_contentBox__G3YGY {
    max-width: 1200px;
    min-height: 100px;
    margin: 0 auto;
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.style_flexLogo__y2A3k{
    display: flex;
    align-items: center;
    gap: 15px;
}
.style_button__N1wFX{
    width: 418px;
    height: 77px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px;
    box-sizing: border-box;
    border: 2px solid rgb(0, 0, 0);
    box-shadow: -4px 4px 0px 0px rgb(0, 0, 0);
    background: rgb(255, 255, 255);
    text-decoration: none;
    color: black;
    font-family: "SourceCodePro-SemiBold";
    font-size: 24px;
    }
    .style_logoTitle__Uxy9b{
        font-family: 'Inter-SemiBold';
        font-size: 28px;
    }
    .style_logoDescription__nYDLj{
        font-family: "Inter-ExtraLight";
        font-size: 20px;
    }

`, "",{"version":3,"sources":["webpack://./src/components/adminPannel/header/style.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,cAAc;IACd,eAAe;IACf,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;AACA;IACI,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,sBAAsB;IACtB,8BAA8B;IAC9B,yCAAyC;IACzC,8BAA8B;IAC9B,qBAAqB;IACrB,YAAY;IACZ,qCAAqC;IACrC,eAAe;IACf;IACA;QACI,6BAA6B;QAC7B,eAAe;IACnB;IACA;QACI,+BAA+B;QAC/B,eAAe;IACnB","sourcesContent":[".contentBox {\n    max-width: 1200px;\n    min-height: 100px;\n    margin: 0 auto;\n    padding: 15px 0;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.flexLogo{\n    display: flex;\n    align-items: center;\n    gap: 15px;\n}\n.button{\n    width: 418px;\n    height: 77px;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    padding: 2px;\n    box-sizing: border-box;\n    border: 2px solid rgb(0, 0, 0);\n    box-shadow: -4px 4px 0px 0px rgb(0, 0, 0);\n    background: rgb(255, 255, 255);\n    text-decoration: none;\n    color: black;\n    font-family: \"SourceCodePro-SemiBold\";\n    font-size: 24px;\n    }\n    .logoTitle{\n        font-family: 'Inter-SemiBold';\n        font-size: 28px;\n    }\n    .logoDescription{\n        font-family: \"Inter-ExtraLight\";\n        font-size: 20px;\n    }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentBox": `style_contentBox__G3YGY`,
	"flexLogo": `style_flexLogo__y2A3k`,
	"button": `style_button__N1wFX`,
	"logoTitle": `style_logoTitle__Uxy9b`,
	"logoDescription": `style_logoDescription__nYDLj`
};
export default ___CSS_LOADER_EXPORT___;
