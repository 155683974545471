// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contactBtn_btnBox__AwL8\\+{
    width: 65px;
    height: 65px;
    border-radius: 13px;
    /* filter: drop-shadow(1px 1px 5px rgb(0, 0, 0)); */
    box-shadow: 0px 0px 8px rgba(66, 66, 66, 0.251);
    display: flex;
    align-items: center;
    justify-content: center;
}
.contactBtn_iconContentAlign__9vv0b{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}
.contactBtn_btnsBlock__FISiy{
    display: flex;
    justify-content: space-between;
    width: 270px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/header/contactButtons/contactBtn.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,mDAAmD;IACnD,+CAA+C;IAC/C,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,QAAQ;AACZ;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,YAAY;IACZ,eAAe;AACnB","sourcesContent":[".btnBox{\n    width: 65px;\n    height: 65px;\n    border-radius: 13px;\n    /* filter: drop-shadow(1px 1px 5px rgb(0, 0, 0)); */\n    box-shadow: 0px 0px 8px rgba(66, 66, 66, 0.251);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n.iconContentAlign{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 8px;\n}\n.btnsBlock{\n    display: flex;\n    justify-content: space-between;\n    width: 270px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnBox": `contactBtn_btnBox__AwL8+`,
	"iconContentAlign": `contactBtn_iconContentAlign__9vv0b`,
	"btnsBlock": `contactBtn_btnsBlock__FISiy`
};
export default ___CSS_LOADER_EXPORT___;
