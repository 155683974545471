import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import s from './style.module.css'
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ImageListItem from '@mui/material/ImageListItem';
// import PhotoView from "../photoView/index"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function MasonryImageList({ list }) {
  const [open, setOpen] = React.useState(false);
  const [galaryInfo, setgalaryInfo] = React.useState(null)
  // const [img, setImg] = React.useState('')
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // React.useEffect(() => {
  //   if(galaryInfo != null){
  //     if(galaryInfo[0].num > galaryInfo[0].count){
  //       {
  //         setgalaryInfo([
  //           {
  //             num: 1,
  //             url: list[0],
  //             count: galaryInfo[0].count
  //           }
  //         ])
  //       }
  //     }
  //   }
  // }, [galaryInfo])

  const handleBack = () => {
    if(galaryInfo[0].num > 0){
      setgalaryInfo([
        {
          num: galaryInfo[0].num - 1,
          url: list[galaryInfo[0].num - 1],
          count: galaryInfo[0].count
        }
      ])
    } else {
      setgalaryInfo([
        {
          num: 10,
          url: list[9],
          count: galaryInfo[0].count
        }
      ])
    }
  }

  const HandleNext = () => {
    if(galaryInfo[0].num < 9){
      setgalaryInfo([
        {
          num: galaryInfo[0].num + 1,
          url: list[galaryInfo[0].num + 1],
          count: galaryInfo[0].count
        }
      ])
    } else {
      setgalaryInfo([
        {
          num: 1,
          url: list[0],
          count: galaryInfo[0].count
        }
      ])
    }
    } 
  return (
    <Box sx={{ width: 500, height: 450, overflowY: 'scroll' ,  '@media (max-width: 768px)': {
      width: 300, height: '100%',
    },}}>
      <div>
        {/* <Button onClick={handleOpen}>Open modal</Button> */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Text in a modal
            </Typography>
            <div className={s.centralFlex}>
              <button onClick={handleBack}>назад</button>
              {galaryInfo != null ? <img width={300} src={galaryInfo[0].url} /> : ''}
              <button onClick={HandleNext}>вперед</button>

            </div>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {galaryInfo != null ? galaryInfo.map((e) => {
                return (
                  <>
                    <p>{list.indexOf(e.url) + 1} из {e.count}</p>
                  </>
                )
              })
                : "отсутствует"}
            </Typography>
          </Box>
        </Modal>
      </div>
      <ImageList variant="masonry" cols={3} gap={8}>
        {list.map((el) => (
          <ImageListItem key={el}>
            <img
              onClick={(e) => {
                handleOpen();
                setgalaryInfo([{
                  count: list.length,
                  url: e.target.currentSrc.split("?")[0],
                  num: list.indexOf(e.target.currentSrc.split("?")[0])
                }])
                // setImg(e.target.currentSrc.split("?")[0])


              }}
              srcSet={`${el}?w=248&fit=crop&auto=format&dpr=2 2x`}
              src={`${el}?w=248&fit=crop&auto=format`}

              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
}


// Модалка

