// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalWindows_modalImg__0yehi{
    max-width: 1200px;
    max-height: 800px
}
.ModalWindows_bottomBtn__ApcRo{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.ModalWindows_btn__R7Z03 {
    padding: 1rem 2rem;
    font-size: 1rem;
    color: white;
    background-color: #3f51b5;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    text-transform: none;
    transition: all 0.3s ease-in-out;
  }
  
  .ModalWindows_btn__R7Z03:hover {
    background-color: #303f9f;
    transform: scale(1.05);
  }

@media (max-width: 600px) {
    .ModalWindows_btn__R7Z03 {
      font-size: 0.875rem;
      padding: 0.75rem 1.5rem;
    }
  }
  
  @media (min-width: 600px) and (max-width: 960px) {
    .ModalWindows_btn__R7Z03 {
      font-size: 1rem;
      padding: 1rem 2rem;
    }
  }
  
  @media (min-width: 960px) {
    .ModalWindows_btn__R7Z03 {
      font-size: 1.25rem;
      padding: 1.25rem 2.5rem;
    }
  }


@media (max-width: 1080px){
    .ModalWindows_modalImg__0yehi{
        height: auto;
        max-width: 500px;
    }
}

@media (max-height: 769px){
    .ModalWindows_modalImg__0yehi{
        height: auto;
        max-width: 350px;
    }
}

@media (max-width: 509px){
    .ModalWindows_modalImg__0yehi{
        height: auto;
        max-width: 300px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/elements/modal/ModalWindows.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB;AACJ;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;AACjC;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,oBAAoB;IACpB,gCAAgC;EAClC;;EAEA;IACE,yBAAyB;IACzB,sBAAsB;EACxB;;AAEF;IACI;MACE,mBAAmB;MACnB,uBAAuB;IACzB;EACF;;EAEA;IACE;MACE,eAAe;MACf,kBAAkB;IACpB;EACF;;EAEA;IACE;MACE,kBAAkB;MAClB,uBAAuB;IACzB;EACF;;;AAGF;IACI;QACI,YAAY;QACZ,gBAAgB;IACpB;AACJ;;AAEA;IACI;QACI,YAAY;QACZ,gBAAgB;IACpB;AACJ;;AAEA;IACI;QACI,YAAY;QACZ,gBAAgB;IACpB;AACJ","sourcesContent":[".modalImg{\n    max-width: 1200px;\n    max-height: 800px\n}\n.bottomBtn{\n    display: flex;\n    align-items: center;\n    justify-content: space-evenly;\n}\n\n.btn {\n    padding: 1rem 2rem;\n    font-size: 1rem;\n    color: white;\n    background-color: #3f51b5;\n    border: none;\n    border-radius: 8px;\n    cursor: pointer;\n    text-transform: none;\n    transition: all 0.3s ease-in-out;\n  }\n  \n  .btn:hover {\n    background-color: #303f9f;\n    transform: scale(1.05);\n  }\n\n@media (max-width: 600px) {\n    .btn {\n      font-size: 0.875rem;\n      padding: 0.75rem 1.5rem;\n    }\n  }\n  \n  @media (min-width: 600px) and (max-width: 960px) {\n    .btn {\n      font-size: 1rem;\n      padding: 1rem 2rem;\n    }\n  }\n  \n  @media (min-width: 960px) {\n    .btn {\n      font-size: 1.25rem;\n      padding: 1.25rem 2.5rem;\n    }\n  }\n\n\n@media (max-width: 1080px){\n    .modalImg{\n        height: auto;\n        max-width: 500px;\n    }\n}\n\n@media (max-height: 769px){\n    .modalImg{\n        height: auto;\n        max-width: 350px;\n    }\n}\n\n@media (max-width: 509px){\n    .modalImg{\n        height: auto;\n        max-width: 300px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalImg": `ModalWindows_modalImg__0yehi`,
	"bottomBtn": `ModalWindows_bottomBtn__ApcRo`,
	"btn": `ModalWindows_btn__R7Z03`
};
export default ___CSS_LOADER_EXPORT___;
