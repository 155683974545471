// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.achivmentTabs_sizeImg__l4l5b{
    width: 350px;
}
@media (max-width: 500px){
    .achivmentTabs_sizeImg__l4l5b{
        width: 150px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/tabs/achivmentTabs.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;IACI;QACI,YAAY;IAChB;AACJ","sourcesContent":[".sizeImg{\n    width: 350px;\n}\n@media (max-width: 500px){\n    .sizeImg{\n        width: 150px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sizeImg": `achivmentTabs_sizeImg__l4l5b`
};
export default ___CSS_LOADER_EXPORT___;
