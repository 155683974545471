// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.works_worksBlock__TXPBK{
    margin-top: 100px;
    height: 755px;
    background-color: #171616;
    display: flex;
    align-items: center;
}
.works_worksContent__F4dSH{
    margin-top: 300px;
    width: 1200px;
    margin: auto;
}
@media (max-width: 500px){
    .works_worksContent__F4dSH{
        width: auto;
        margin: auto;
    }
    .works_worksBlock__TXPBK{
        margin-top: 50px;
        height: 800px;
        padding: 30px 0;
        background-color: #171616;
        display: flex;
        align-items: center;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/main/works.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;IACb,yBAAyB;IACzB,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,iBAAiB;IACjB,aAAa;IACb,YAAY;AAChB;AACA;IACI;QACI,WAAW;QACX,YAAY;IAChB;IACA;QACI,gBAAgB;QAChB,aAAa;QACb,eAAe;QACf,yBAAyB;QACzB,aAAa;QACb,mBAAmB;IACvB;AACJ","sourcesContent":[".worksBlock{\n    margin-top: 100px;\n    height: 755px;\n    background-color: #171616;\n    display: flex;\n    align-items: center;\n}\n.worksContent{\n    margin-top: 300px;\n    width: 1200px;\n    margin: auto;\n}\n@media (max-width: 500px){\n    .worksContent{\n        width: auto;\n        margin: auto;\n    }\n    .worksBlock{\n        margin-top: 50px;\n        height: 800px;\n        padding: 30px 0;\n        background-color: #171616;\n        display: flex;\n        align-items: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"worksBlock": `works_worksBlock__TXPBK`,
	"worksContent": `works_worksContent__F4dSH`
};
export default ___CSS_LOADER_EXPORT___;
