// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about_contentBlock__pXiCe {
    height: 730px;
    width: 1200px;
    margin: auto;
}

@media (max-width: 500px){
    .about_contentBlock__pXiCe {
        height: 480px;
        width: auto;
        margin: auto;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/main/about.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,YAAY;AAChB;;AAEA;IACI;QACI,aAAa;QACb,WAAW;QACX,YAAY;IAChB;AACJ","sourcesContent":[".contentBlock {\n    height: 730px;\n    width: 1200px;\n    margin: auto;\n}\n\n@media (max-width: 500px){\n    .contentBlock {\n        height: 480px;\n        width: auto;\n        margin: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentBlock": `about_contentBlock__pXiCe`
};
export default ___CSS_LOADER_EXPORT___;
