// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_areaBox__qg4EI {
    /* Button */
    /* position: relative; */
    width: 900px;
    height: -moz-fit-content;
    height: fit-content;
    /* left: 300px;
    top: -1550px; */
    box-sizing: border-box;
    border: 2px solid rgb(0, 0, 0);
    box-shadow: -4px 4px 0px 0px rgb(0, 0, 0);
    background: rgb(255, 255, 255);
    margin-top: 40px;
    padding-bottom: 40px;
}`, "",{"version":3,"sources":["webpack://./src/components/adminPannel/ContentArea/style.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,wBAAwB;IACxB,YAAY;IACZ,wBAAmB;IAAnB,mBAAmB;IACnB;mBACe;IACf,sBAAsB;IACtB,8BAA8B;IAC9B,yCAAyC;IACzC,8BAA8B;IAC9B,gBAAgB;IAChB,oBAAoB;AACxB","sourcesContent":[".areaBox {\n    /* Button */\n    /* position: relative; */\n    width: 900px;\n    height: fit-content;\n    /* left: 300px;\n    top: -1550px; */\n    box-sizing: border-box;\n    border: 2px solid rgb(0, 0, 0);\n    box-shadow: -4px 4px 0px 0px rgb(0, 0, 0);\n    background: rgb(255, 255, 255);\n    margin-top: 40px;\n    padding-bottom: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"areaBox": `style_areaBox__qg4EI`
};
export default ___CSS_LOADER_EXPORT___;
